@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: "12px";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
} */
.remove-srollbar ::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.remove-srollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.backgroundImage {
  background-image: url(../src/assets/img/background.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
::-webkit-scrollbar {
  width: 8px !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background: #002e66 !important;
}
::-webkit-scrollbar-track {
  background-color: transparent !important;
}
